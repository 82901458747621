import React, { useState, useEffect } from 'react';
import Loader from './Loader';
import '../styles/PromptInput.css'; // Import the CSS file

const IMAGEN_URL = 'https://pygmart.com/api/imgen';
const TORCH_URL = 'https://pygmart.com/api/torch';
console.log('IMAGEN_URL:', IMAGEN_URL);

const PromptInput = () => {
    const [prompt, setPrompt] = useState('');
    const [imageUrls, setImageUrls] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [sliderChecked, setSliderChecked] = useState(false);
    const [lastGeneratedImage, setLastGeneratedImage] = useState('');

    const handleInputChange = (event) => {
        setPrompt(event.target.value);
    };

    const handleTextareaInput = (event) => {
        event.target.style.height = 'auto';
        event.target.style.height = `${event.target.scrollHeight}px`;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError('');
        try {
            console.log('Submitting to:', `${IMAGEN_URL}/generate`);
            const response = await fetch(`${IMAGEN_URL}/generate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ prompt }),
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to generate image');
            }
            const contentType = response.headers.get('Content-Type');
            if (contentType && contentType.includes('application/json')) {
                const data = await response.json();
                console.log('Raw image paths returned:', data.image_paths);
                console.log('Response data:', data); // Debugging line
                if (data.image_paths) {
                    setImageUrls(data.image_paths);
                    const lastImage = data.image_paths[data.image_paths.length - 1];
                    setLastGeneratedImage(lastImage); // Set the last generated image
                    console.log('Image URLs set:', data.image_paths); // Debugging line
                    console.log('Last generated image:', lastImage); // Debugging line
                } else {
                    throw new Error('Image paths not found in response');
                }
            } else {
                throw new Error('Unexpected response type');
            }
        } catch (error) {
            console.error('Error generating image:', error);
            setError(`Error generating image: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleSliderChange = async (event) => {
        setSliderChecked(event.target.checked);
        if (event.target.checked && lastGeneratedImage) {
            try {
                console.log('Sending filename to execute_script:', lastGeneratedImage); // Debugging line
                const response = await fetch(`${TORCH_URL}/process_image`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ image_filename: lastGeneratedImage }),
                });
                if (!response.ok) {
                    throw new Error('Failed to execute script');
                }
                const data = await response.json();
                console.log('Script executed successfully', data);

                window.opener.postMessage({ event: 'processImageResponse', data }, '*');

                window.close(); // Close the window
            } catch (error) {
                console.error('Error executing script:', error);
                setError(`Error executing script: ${error.message}`);
            }
        }
    };

    return (
        <div className="prompt-input-container">
            <div className="image-urls-container">
                {loading && <Loader className="loader-container" />}
                {imageUrls.map((url, index) => (
                    <img key={index} src={`${IMAGEN_URL}/${url}`} alt={`Generated ${index}`} className="generated-image" />
                ))}
            </div>
            <div className="slider-container">
                <label htmlFor="ready-slider">Ready</label>
                <input
                    type="checkbox"
                    id="ready-slider"
                    checked={sliderChecked}
                    onChange={handleSliderChange}
                    className="slider"
                />
            </div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <form onSubmit={handleSubmit} className="prompt-form">
                <textarea
                    value={prompt}
                    onChange={handleInputChange}
                    onInput={handleTextareaInput}
                    placeholder="Enter your prompt"
                    className="prompt-input"
                />
                <button className='prompt-button' type="submit">Generate Image</button>
            </form>
            
            
        </div>
    );
};

export default PromptInput;