import React, { useState } from 'react';
import PromptInput from './components/PromptInput';
import './styles/App.css';

function App() {
    const [imageUrl, setImageUrl] = useState('');

    const handleImageGenerated = (url) => {
        setImageUrl(url);
    };

    return (
        <div className="App">
            <h1 style={{ textAlign: 'center' }}>Pygmart AI</h1>
            <PromptInput onImageGenerated={handleImageGenerated} />
            {imageUrl && <img src={imageUrl} alt="Generated" />}
        </div>
    );
}

export default App;