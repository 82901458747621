import React from 'react';
import '../styles/Loader.css'; // Create a CSS file for loader styles

const Loader = () => {
  return (
    <div className="loader">
      
    </div>
  );
};

export default Loader;